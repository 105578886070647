.TicketData__container {
	padding: 24px 0 0 0;
}

.ticketData__form--container {
	width: 100%;
	text-align: start;
}

.ticketData_form--inputGroup {
	margin: 12px 0 12px 0;
}
.ticketData_form--checkbox {
	margin: 12px 0 12px 0;
	display: flex;
	align-items: center;
}

.TicketData__img {
	width: 100%;
	border-radius: 16px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.35);
}
