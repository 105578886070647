.BillingFinished__Icon {
	font-size: 200px;
}

@media only screen and (max-width: 800px) {
	.BillingFinished__Icon {
		font-size: 180px;
	}
}

@media only screen and (max-width: 600px) {
	.BillingFinished__Icon {
		font-size: 150px;
	}
}
