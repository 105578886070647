.Footer__container {
}

.contact__container {
	margin: 0 auto;
	width: 50%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}
