.appbar {
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* border-radius: 0 0 16px 16px; */
	/* transition: all 0.2s linear; */
}

.appbar__small {
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* border-radius: 0 0 16px 16px; */
	/* transition: all 0.2s linear; */
}

.appbar__small .img {
	min-height: 24px;
	max-height: 40px;
	margin: 0 auto;
	width: 60%;
	height: 60%;
	object-fit: contain;
	/* transition: all 0.2s linear; */
}
.appbar__small .header__title {
	font-size: 1.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
	/* transition: all 0.2s linear; */
}

.img_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.img {
	min-height: 48px;
	max-height: 70px;
	margin: 0 auto;
	width: 50%;
	height: 50%;
	object-fit: contain;
	/* transition: all 0.2s linear; */
}

.header__title {
	font-size: 2rem;
	overflow: hidden;
	text-overflow: ellipsis;
	/* transition: all 0.2s linear; */
	padding-left: 10px;
}

@media only screen and (max-width: 600px) {
	.header__title {
		font-size: 1.8rem;
		padding-left: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.img {
		min-width: 48px;
		margin: 0 auto;
		width: 60%;
		object-fit: contain;
	}
}

@media only screen and (max-width: 400px) {
	.header__title {
		font-size: 1.5rem;
		padding-left: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
